/* eslint-disable no-shadow */
import {AccessLevelData} from '_components/admin/admin.types';
import {LoginFormProps} from '_components/login/login.types';
import {
  ResetPasswordTokenFormProps,
  ResetPasswordFormProps,
} from '_components/forgot/forgot.types';
import {
  StripePaymentPlanName,
  StripePaymentPlanType,
} from '../payment/user-payment.types';

export enum UserRoles {
  admin = 'admin',
  mentalhealthcoach = 'mentalhealthcoach',
  therapist = 'therapist',
  prescriber = 'prescriber',
  member = 'patient',
  loggedout = 'loggedout',
  unverified = 'unverified',
}

export enum AdminRoles {
  superAdmin = 'super_admin',
  communityAdmin = 'community_admin',
  prescriberAdmin = 'prescriber_admin',
  adminAdmin = 'admin_admin',
  patientAdmin = 'patient_admin',
  therapistAdmin = 'therapist_admin',
}

export type BaseProfile = {
  fullName: string;
  role: UserRoles;
  email: string;
  image: string;
  countryOfResidence: {
    name: string;
    code: string;
  };
};

export enum UserAccountType {
  scale = 'scale',
  wellnite = 'wellnite',
}
export enum InsuranceCompanyType {
  none = 'none',
  public = 'public',
  commercial = 'commercial',
}
export enum DigitalPracticeAccountType {
  outOfPocket = 'out_of_pocket',
  insurance = 'insurance',
}

export type InsuranceDetails = {
  companyType: InsuranceCompanyType;
  companyName: string;
  companyId: string;
  dependencyStatus: string;
  memberId: string;
  onboardStage: number;
  doctorFee: number;
  therapistFee: number;
  primaryInsuredCity: string;
  primaryInsuredFirstName: string;
  primaryInsuredMiddleName: string;
  primaryInsuredLastName: string;
  primaryInsuredState: string;
  primaryInsuredStreetAddress: string;
  primaryInsuredZipcode: string;
  primaryInsuredGender: string;
  primaryInsuredDateOfBirth: {
    day: string;
    month: string;
    year: string;
  };
};

export type MemberProfile = BaseProfile & {
  verified: boolean;
  prescriberDetails: {
    prescriberId: string;
    fullName: string;
    email: string;
    image: string;
  };
  therapistDetails: {
    therapistId: string;
    fullName: string;
    email: string;
    image: string;
  };

  jobTitle: string;
  companyName: string;
  compamyShouldPay?: boolean;

  subscriptionStatus: string;
  patientId: string;
  _id: string;
  phoneNumber: string;
  isPhoneVerified?: boolean;
  notifications: string[];
  dateOfBirth: Date;
  stateOfResidence: string;
  currentMedications: string[];
  allergies: string[];
  shippingAddress: {
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  insuranceImageBack: string;
  insuranceImageFront: string;
  regStage: string;
  gender: string;
  pregnancyInfo: string[];
  drugAllergies: string;
  visADocForAnxDep: string;
  diagnosis: string[];
  existingConditions: string[];
  medicationsForAnxDep: string;
  purposeOfReg: string;
  anxiety: {
    nervous: string;
    controlWorrying: string;
    worryingTooMuch: string;
    troubleRelaxing: string;
    restlessness: string;
    easilyAnnoyed: string;
    fear: string;
  };
  depression: {
    littleInterest: string;
    feelingDown: string;
    sleepingTroubles: string;
    feelingTired: string;
    underOrOverEating: string;
    feelBadAboutSelf: string;
    troubleConcentrating: string;
    slowMovtOrFidgety: string;
    thoughtsOfHurtingSelf: string;
  };
  paidOrTrialAccess: {
    access: boolean;
    message: string;
  };
  suicidalThoughts: string;
  currentlySuicidal: string;
  seizure: string;
  paymentPlan: StripePaymentPlanName;
  __subPaymentPlan: StripePaymentPlanType;
  patientCards: {id: string; brand: string; last4: string}[] | string;
  stripeCustomerCard: {brand: string; last4: string} | string;
  currentGenderIdentification: string;
  localPharmData: {
    pharmName: string;
    pharmAddress: string;
  };
  referral?: {
    referralCode: string;
    referralCount: number;
    referralCoinsTogether: number;
  };
  accountType: UserAccountType;
  socialOnboardingExtraFlow?: string;
  charges: {
    medicalOutOfPocket: number;
    medicalInsurAll: number;
    medicalInsur10: number;
    medicalInsur30: number;
    therapyOutOfPocket: number;
    therapyInsurAll: number;
    therapyInsur30: number;
    therapyInsur45: number;
    therapyInsur60: number;
  };
  digitalPracticeAccountType?: 'out_of_pocket' | 'insurance';
  stripeSubscriptionId?: string;
  insuranceDetails?: InsuranceDetails;
};

export type CoachProfile = BaseProfile & {
  mentalHealthCoachId: string;
  notifications: string[];
  acuity: {calendarId: string; timezone: string};
};

export type DoctorProfile = BaseProfile & {
  _id: string;
  prescriberId: string;
  title: string;
  insuranceType: string;
  states: string[];
  dailyCoUrl: string;
  dailyCoToken: string;
  secondaryEmail?: string;
  acuity: {calendarId: string; timezone: string};
  isTestProvider?: boolean;
  deelContractId: string | null;
  selfie: string;
  governmentId: {
    type: string;
    frontImage: string;
    backImage: string;
  };
};
type ProfileWithoutCountry = Omit<BaseProfile, 'countryOfResidence'>;

export type AdminProfile = ProfileWithoutCountry & {
  adminId: string;
  gender: string;
  adminRoles: string[];
  accessLevel: AccessLevelData[];
};

export type TherapistCredentialsType = {
  type: string;
  data: {
    credentialType?: string;
    organization?: string;
    membershipType?: string;
    membershipAbbreviation?: string;
    nameOrId?: string;
    memberSince?: string;
    licenseIssuer?: string;
    licenseType?: string;
    licenseAbbreviation?: string;
    licenseNumber?: string;
    licenseIssueDate?: string;
    accreditingInstitution?: string;
    certificationName?: string;
    certificationAbbreviation?: string;
    certificateNumber?: string;
    year?: string;
    departmentOrSubject?: string;
    abbreviation?: string;
    diplomaName?: string;
    degreeName?: string;
  };
};

export type TherapistProfile = BaseProfile & {
  _id: string;
  therapistId: string;
  acuity: {calendarId: string; timezone: string};
  dailyCoUrl: string;
  dailyCoToken: string;
  secondaryEmail?: string;
  states: string[];
  accountType?: string;
  isInitialTherapyBillable: boolean;
  isTestProvider?: boolean;
  isEligibleForPaidInitialSession: boolean;
  totalHoursForTheWeek: number;
  isBillableStatusTampered: boolean;
  deelContractId: string | null;
  countryOfResidence: {
    code: string;
    name: string;
  };
  defaultPaymentService: string;
  verifiedByWellniteTeam: boolean;
  selfie: string;
  address: {
    addressLine: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
  };
  mentalHealthRole: string;
  academicInitials: string;
  credentials: TherapistCredentialsType[];
  governmentId: {
    type: string;
    frontImage: string;
    backImage: string;
  };
};

export type UserProfile =
  | MemberProfile
  | CoachProfile
  | TherapistProfile
  | DoctorProfile
  | AdminProfile;

export type NonAdminUserProfile = Exclude<UserProfile, AdminProfile>;

export type CurrentUser =
  | MemberProfile
  | CoachProfile
  | TherapistProfile
  | DoctorProfile
  | AdminProfile
  | null;

export type LoginCredentials = LoginFormProps & {
  setSubmitting(isSubmitting: boolean): void;
  redirectTo: string;
};

export type ResetPasswordTokenCredentials = ResetPasswordTokenFormProps & {
  setSubmitting(isSubmitting: boolean): void;
};

export type ResetPasswordCredentials = ResetPasswordFormProps & {
  setSubmitting(isSubmitting: boolean): void;
};
